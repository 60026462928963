* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 16px;
  overflow-y: scroll;
  /* position: relative; */
}

body {
  padding-top: 80px; /* This should be equal to the height of your header */
  background-color: #110f0e !important;
}

.tradePriceContainer {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 200;
}

.modal-header {
  background-color: #000000a3;
}

.modal-body {
  background-color: #000000a3;
}

.modal-content {
  border: 4px solid #db941a !important;
}

.priceNavbar {
  padding: 0 !important;
  background-color: #035a0a;
}

.tradePriceContainer {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 200;
}

.dynamicNavbar {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 200;
}

.instaLoader {
  display: block;
}
.backgroundAnimation {
  height: 4px;
  background: #27c4f5
    linear-gradient(to right, yellow, red, rgb(82, 0, 82), rgb(48, 48, 240));
  background-size: 500%;
  -webkit-animation: 2s linear infinite barprogress, 0.3s fadein;
  animation: 2s linear infinite barprogress, 0.3s fadein;
  width: 100%;
}
@-webkit-keyframes barprogress {
  0% {
    background-position: 125% 0;
  }
  to {
    background-position: 0% 0;
  }
}
@keyframes barprogress {
  0% {
    background-position: 125% 0;
  }
  to {
    background-position: 0% 0;
  }
}

@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

#logo {
  font-size: 26px !important;
}

.absoluteTop {
  position: absolute;
  top: 0;
  left: 0;
}

textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
[type="text"].form-control:focus,
[type="password"].form-control:focus,
[type="email"].form-control:focus,
[type="tel"].form-control:focus,
[contenteditable].form-control:focus {
  box-shadow: none;
}

.navbar-nav .dropdown-menu {
  position: absolute !important;
  background-color: black;
}

/* ============== Loader ================================== */
.loader {
  width: 14px;
  height: 14px;
  border: 2px solid #fff;
  border-bottom-color: transparent;
  border-radius: 68%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 0.5s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* ================================================ */
/* DOT LOADER */
/* ================================================ */

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #cbe596;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

#statusStyle {
  color: rgb(211, 175, 16);
}

/* ============= Top Nav bar ===================== */

#accountDropDown {
  background-color: transparent;
  border: none;
  color: #757272;
}

.topNavLinkWrapper {
  width: 100%;
  height: 100%;
}

.topNavContainer {
  width: 30%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#topNavLink {
  text-decoration: none;
  font-weight: 500;
}

#avatar {
  height: 32px;
  width: 32px;
  margin-top: 5px;
  cursor: pointer;
}

#backHomeLink {
  text-decoration: none;
  color: #696666;
}

.noDecoration {
  text-decoration: none;
}

.loggedNav {
  display: flex;
  justify-content: space-around;
  width: 40%;
  height: 60px !important;
  align-items: center;
  color: #696666;
}

#loggedLink {
  color: gray;
  text-decoration: none;
  display: block;
  /* margin: 0 20px; */
}

#loggedLink:hover {
  color: white !important;
}

a.dropdown-item:hover {
  background-color: #f28705 !important;
  color: white !important;
}

a.dropdown-menu:hover {
  color: white !important;
}
/* ================= Banner Section ================================= */
#bannerImg {
  height: 100%;
  width: 100%;
}

.textColumn {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.bannerText {
  margin-left: 1.6rem;
}

#bannerText1 {
  font-size: 3.125rem;
  font-weight: 700;
  color: #71a704;
}

#bannerText2 {
  color: rgb(66 67 67);
}

#startBtn {
  width: 40%;
  height: 2.5rem;
  border: none;
  background-color: #ee9e12;
  color: #fff;
  font-weight: 600;
  margin-left: 1.6rem;
  margin-top: 1.25rem;
  border-radius: 0.3rem;
}

#hero-bg {
  background-image: url("./assets/heroImg.webp");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mask {
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 71%);
  text-align: center;
  text-shadow: 1px 1px 1px#000;
  padding-top: 130px;
  color: #fff;
}

.mask h1:first-child {
  color: #f24405;
}

.mask h1 {
  font-size: 45px;
  font-weight: 700;
}

#hero-btn {
  width: 30%;
  height: 3.5rem;
  border: none;
  background-color: #f28705c7;
  color: #fff;
  font-weight: 600;
  margin-top: 1.25rem;
  border-radius: 0.3rem;
}

/* =================== About Section ======================= */
.aboutSection {
  padding-top: 7rem;
  color: #fff;
}

.aboutText {
  text-align: center;
  font-weight: 600;
}

#aboutText1 {
  color: #f2f0e4;
}

#aboutText2 {
  color: #f24405;
}

#aboutTextParagraph {
  line-height: 33px;
}

#aboutImg {
  height: 100%;
  width: 100%;
  color: rgb(66 67 67);
}

/* ===================== Why Choose us ================================ */
/* .chooseUsSection {
  padding-top: 20px;
} */

.whyChooseUsText {
  margin: 40px 0;
  text-align: center;
}

.chooseUs {
  background-color: #212529a3;
  border-radius: 5px;
  color: #f2f0e4;
  font-size: 1rem;
  padding: 2.1rem;
  text-align: center;
}

#chooseUsIcon {
  width: 40px;
  height: 40px;
}

#chooseUsTitle {
  color: #f25c05;
}

/* ================ Investment Plan Section ====================== */
#InvestmentPlans {
  margin: 40px 0;
  padding-bottom: 50px;
}

.planCard {
  height: 400px;
  background-color: #eaf3fa;
  font-size: 1rem;
  padding: 2.1rem;
  text-align: center;
  border-radius: 5px;
  box-shadow: 1px 2px 8px #71a704;
  transition: transform 0.3s;
}

.planCard:hover {
  transform: translateY(-1.3rem) scale(1);
}

.planCard h2 {
  color: #71a704;
  font-family: monospace;
}

.planCard span {
  color: #478ae7;
  font-weight: 600;
}

.planCard h3 {
  color: #243863;
}

.plansRow {
  padding: 40px 0;
}

.startPlanBtn {
  background-color: #ee9e12;
  width: 70%;
  height: 2.5rem;
  color: #fff;
  font-weight: 600;
  margin-top: 1.25rem;
  border: none;
  border-radius: 0.3rem;
}

.startPlanBtn:hover {
  background-color: #db941a;
}

/* ============== Testimony Section ============================ */
#testimonyCard {
  margin: 10px 0;
  height: 310px;
  text-align: center;
  padding: 16px;
}

#testimonyImg {
  width: 100px;
  height: 100px;
  border-radius: 100%;
}

.testimonyText {
  padding-bottom: 40px;
}

/* ============= Contact Info ======================== */

#contactUs {
  margin-top: 60px;
}

.contactContainer {
  padding-top: 60px;
}

.contactRow {
  margin: 90px;
}

.contactInfo {
  width: 100%;
  height: 375px;
  background-color: #f28705;
  padding: 40px;
}

#contactBtn {
  width: 100%;
  height: 50px;
  border: none;
  background-color: #f28705;
  color: #fff;
  font-weight: 400;
  font-family: monospace;
}

.contactWrapper {
  padding: unset;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.contactWrapper li {
  list-style: none;
  color: #fff;
}

.contactWrapper li span {
  margin-left: 20px;
}

#contactIcon {
  width: 32px;
  height: auto;
}

#contactLabel {
  color: #f28705;
  font-size: 20px;
  font-weight: 400;
}

/* ============= Sponsors =================== */

.center {
  display: flex;
  justify-content: center;
}

#sponsors {
  width: 120px;
  height: auto;
  filter: grayscale(100%);
}

/* =============== Footer =============== */
.footer {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  color: #fff;
  position: absolute;
  bottom: 0;
}

.homeFooter {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  color: #fff;
}

#footerLogo {
  color: #71a704;
}

/* ============= popOver =================== */
.popingCard {
  width: 280px;
  padding: 10px 28px;
  background-color: #71a704;
  color: #fff;
  border-radius: 5px;
  margin: 0 !important;
  position: fixed;
  top: 80%;
  left: 76%;
}

.pop {
  display: unset;
}

.unpop {
  display: none;
}

#popupCountry {
  font-weight: 800;
  /* color: rgb(66 67 67); */
}

/* ================= Login ====================== */

.formContainer {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.formHolder {
  padding: 20px;
  padding-top: 15px;
  width: 50%;
  color: #fff;
  background-color: #212529a3;
}

#formLabel {
  color: #edb267;
  font-weight: 500;
}

#formTitle {
  color: #f24405;
  margin-bottom: 30px;
}

#logBtn {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  height: 50px;
  border: none;
  background-color: #f28705;
  color: #fff;
  font-weight: 400;
  font-family: monospace;
}

#actionBtn {
  width: 80px;
  height: 45px;
  border: none;
  border-radius: 5px;
  background-color: green;
  color: #fff;
  font-weight: 400;
  font-family: monospace;
}

/* ========== Captcher ============= */
.captchaBox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  background-color: #243863;
  margin-bottom: 10px;
  text-align: center;
  color: rgb(207, 203, 203);
  font-weight: 700;
  font-size: 25px;
}

.captchaContainer {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

#code1 {
  rotate: 14deg;
}

#code2 {
  rotate: -47deg;
}

#code3 {
  rotate: -7deg;
}

#code4 {
  rotate: 42deg;
}

#code5 {
  rotate: 20deg;
}

#code6 {
  rotate: 4deg;
}

.signupWrapper {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
}

.signUp {
  width: 50%;
  padding: 20px;
  margin: 20px 0;
  background-color: #212529a3;
  color: #fff;
}

#resetform {
  height: 250px;
}

#restpassword {
  height: 75vh;
}

/* ============== Dashboard ======================== */

.dashboardCard {
  width: 100%;
  height: 100px;
  border-radius: 10px;
  background-color: #212529a3;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

.cover{
  display: flex;
  width: 100%;
  padding: 0 20px;
}

.partOne{
  width: 100%;
}

.clipSection {
  display: flex;
  margin-bottom: 40px;
}

.clipboardinput {
  width: 100%;
  height: 50px;
  padding: 0 20px;
  border: none;
  outline: 1px solid #edb267;
  background-color: #edb267;
  color: rgb(48 47 47);
}

.clipIconHolder {
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f24405;
  outline: 1px solid #f24405;
  cursor: pointer;
}

#clipIcon {
  width: 32px;
  height: 32px;
}

#dashboardLabel {
  font-size: 18px;
  font-weight: 500;
  color: #f24405;
}

.tableWrapper {
  overflow-x: auto;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  color: rgb(48 47 47);
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px 15px;
  white-space: nowrap;
}

th {
  background-color: #000000a3;
  color: #fff;
}
tr {
  background-color: #a7a4a4;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

.table-responsive {
  padding-bottom: 60px;
}

.referralPage {
  height: 80vh;
  padding-top: 30px !important;
}

#manipulateBtn {
  cursor: pointer;
  padding: 6px;
  background-color: green;
}


#deleteBtn {
  cursor: pointer;
  padding: 6px;
  background-color: red;
}

#deleteBtn:hover {
  background-color: rgb(148, 8, 8);
}

#updateBtn {
  cursor: pointer;
  padding: 6px;
  background-color: rgb(255, 187, 0);
  color: #fff;
}

#updateBtn:hover {
  background-color: rgb(219, 163, 9);
}

#referrenceID {
  color: #006aff;
}

#plusAmount {
  color: rgb(10, 148, 17);
}

.contactWrapper li {
  display: flex;
}

/* ============== Profile ========================== */
.profile {
  background-color: #212529a3;
  width: 70%;
  padding: 25px;
}

#cantChange {
  outline: none;
  border: none;
  background-color: #edb267;
  color: rgb(48 47 47);
}

#sideNote {
  color: #757272;
  font-size: 14px;
}

#clipwrapper {
  margin-bottom: 20px;
}

/* ================= Avatar ===================== */

.avatarWrapper {
  width: 100%;
  height: 180px;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  position: relative;
}

.addProfile {
  position: absolute;
  bottom: 4%;
  left: 57%;
}

.avatar {
  width: 180px;
  height: 100%;
  background-color: #edeeee;
  border-radius: 100%;
  /* border: 2px solid greenyellow; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transition: ease-in 0.5s;
}

.profileIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  margin-bottom: 0;
  border-radius: 100%;
  background: #ffffff;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 12%);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
}

#profileImage {
  display: none !important;
}

.editIcon {
  width: 50%;
}

/* =========== notification =================== */

#alert {
  padding: 12px;
}

.notification {
  width: 270px;
  position: fixed;
  top: 12%;
  left: 50%;
  z-index: 1;
  animation: moveInTop 1s;
}

#google_translate_element select {
  padding: 10px 16px;
  margin-left: 3px;
  background-color: transparent;
  border: 1px solid #71a704;
  border-radius: 5px;
  color: #504f4f;
}
#google_translate_element div {
  font-size: 0;
  margin-top: 11px;
}
#google_translate_element span {
  display: none !important;
}

.butt {
  background-color: #035a0a !important;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 15px !important;
  padding: 4px 6px;
  width: 100px;
  height: 40px;
}

.inactive {
  background-color: #2a814e !important;
}

.loaderDiv {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.fetchLoader {
  width: 48px;
  height: 48px;
  border: 5px solid #fff;
  border-bottom-color: #2a814e;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  box-shadow: 0px 0px 20px 0px #a3a2a2;
}

.sponsorContainer {
  margin-top: 20px;
  padding-bottom: 50px;
}

#fullHeight {
  height: 120vh;
}

.gt_switcher_wrapper {
  all: unset !important;
  position: relative !important;
  z-index: 1 !important;
}

.gt_option {
  position: absolute !important;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.chartParent {
  width: 100%;
  height: 600px;
  display: flex;
  justify-content: center;
  padding: 40px 100px;
}

/* ============= Animations ===================== */
@keyframes moveInTop {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }

  80% {
    transform: translateX(-10px);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@media screen and (max-width: 48rem) {
  #hero-bg {
    height: 400px;
  }

  .mask h1 {
    font-size: 30px;
    font-weight: 700;
  }

  #hero-btn {
    width: 37%;
    height: 2.5rem;
  }

  .chartParent {
    padding: 20px 10px;
  }


  .aboutText {
    padding-top: 20px;
  }
  #banner {
    margin-top: 33px;
  }
  /* #bannerImg {
    height: 100%;
    width: 90vw;
  } */

  #bannerText1 {
    font-size: 2.125rem;
  }

  /* #aboutImg {
    height: 100%;
    width: 90vw;
  } */

  #aboutTextParagraph {
    padding: 0 10px;
  }

  .bannertRow {
    flex-direction: column !important;
  }

  .aboutRow {
    flex-direction: column-reverse !important;
  }

  .aboutSection {
    padding-top: 1.5625rem;
  }

  .plansRow {
    flex-direction: column !important;
    padding-bottom: 0;
  }

  .planCard {
    margin: 10px;
  }

  .planCard:hover {
    transform: translateY(0) scale(1);
  }

  .last {
    display: none;
  }

  #testimonyCard {
    margin: 10px 0;
    margin-bottom: 20px;
    height: 459px;
    padding: 16px;
  }

  .contactRow {
    margin: 0px;
  }

  .contactContainer {
    padding-top: 0px;
    flex-direction: column !important;
  }

  .contactInfo {
    margin-top: 20px;
    padding: 40px 20px;
  }

  #sponsors {
    /* width: 200px; */
    height: auto;
    object-fit: contain;
  }

  .popingCard {
    width: 258px;
    top: 72%;
    left: 27%;
    padding: 10px 21px;
  }

  .formHolder {
    width: 100%;
    /* height: 540px; */
    margin-bottom: 20px;
  }

  .signUp {
    width: 100%;
  }

  .profile {
    width: 100%;
  }

  .clipboardinput {
    padding: 7px;
  }

  .chooseUs {
    margin-top: 20px;
  }

  .notification {
    left: 43%;
  }

  .loggedNav {
    width: 100%;
    justify-content: space-between;
    margin-top: 20px;
  }

  #cryptoDetails {
    flex-direction: column !important;
  }

  #code1 {
    rotate: 61deg;
  }

  #code2 {
    rotate: 320deg;
  }
  #code3 {
    rotate: 316deg;
  }

  #code4 {
    rotate: 71deg;
  }

  #code5 {
    rotate: 349deg;
  }

  #code6 {
    rotate: 315deg;
  }

  .avatar {
    width: 151px;
    height: 151px;
  }

  .addProfile {
    bottom: 14%;
    left: 57%;
  }

  .dashboardCard {
    font-size: 14px;
  }

  #homeNavbar {
    position: fixed;
    top: 5.4%;
  }

  #contactBtn {
    background-color: #71a704;
  }

  .whyChooseUsText {
    margin-bottom: 10px;
  }

  .dynamicNavbar {
    display: flex !important;
    flex-direction: column-reverse !important;
  }

  .instaLoader {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 12;
  }
}
